import React from "react";
import styled from "styled-components";

const Btn = styled.button`
  display: inline-block;
  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  outline: none;
  border: none;
  width: 200px;
  font-size: ${(props) => props.theme.fontmd};
  padding: 0.9rem 2.3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top:1rem;
  align-items:center !important;
  position: relative;
  margin:5px;

  &:hover {
    transform: scale(0.9);
  }

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 5px solid #fbbe16;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
  }

  &:hover::after {
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const Button = ({ text, link, resim }) => {
  return (
    <a href={link} aria-label={text} target="_blank" rel="noreferrer">
      <Btn>
        <div style={{ display: "flex",alignItems:"center",textAlign:"center",justifyContent:"center" }}>
          {text}
          <img src={resim} className="iconresim" />
        </div>
      </Btn>
    </a>
  );
};

export default Button;
