import Pdf from "./components/pdf/privacy-policy.pdf";
export const menuItems = [
  {
    title: "Features",
    url: "",
    submenu: [
      {
        title: "Swap",
        url: "https://swap.balancenetwork.io/swap",
      },
      {
        title: "Buy Crypto",
        url: "https://www.balancenetwork.io/buy",
      },
    ],
  },
  {
    title: "Support",
    url: "",
    submenu: [
      {
        title: "Get Support",
        url: "mailto:support@balancenetwork.io",
      },
      {
        title: "Community",
        url: "https://t.me/BalanceNetwork",
      },
    ],
  },

  {
    title: "Docs",
    url: "",
    submenu: [
      {
        title: "Privacy Policy",
        url: "https://docs.wallet-balancenetwork.io/views/balanceWallet/privacy_policy.html",
      },
      {
        title: "Terms Of Use",
        url: "https://docs.wallet-balancenetwork.io/views/balanceWallet/term_of_use.html",
      },
    ],
  },
];
