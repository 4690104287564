import React, { lazy, Suspense } from "react";
import styled from "styled-components";
// import Banner from './Banner'
import Logo from "./Logo";

import Facebook from "../Icons/Facebook";
import Instagram from "../Icons/icons8-reddit-48.png";
import Twitter from "../Icons/icons8-twitter-48.png";
import LinkedIn from "../Icons/icons8-medium-48.png";
import Dc from "../Icons/icons8-discord-48.png";
import Tl from "../Icons/icons8-telegram-app-48.png";
import Loading from "./Loading";
import SOCIAL_MEDIA from "./social.json";

import Icon from "./iconscopy/index";
import { FooterNavigation } from "../config/menu.config";

const Banner = lazy(() => import("./Banner"));

const Section = styled.section`
  min-height: 0vh;
  width: 100vw;
  background-color: ${(props) => props.theme.body};
  position: relative;
  color: ${(props) => props.theme.text};

  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
`;

const Container = styled.div`
  width: 75%;
  margin-top: 100px;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fontlg};

  // border-bottom: 1px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 90%;

    h1 {
      font-size: ${(props) => props.theme.fontxxxl};
    }
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 100%;
  }
`;

const IconList = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem auto;

  & > * {
    padding-right: 0.5rem;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
`;
const MenuItems = styled.ul`
  list-style: none;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;

  @media (max-width: 48em) {
    display: none;
  }
`;
const Item = styled.li`
  width: fit-content;
  cursor: pointer;

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.text};
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }
`;

const Bottom = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => props.theme.fontlg};

  a {
    text-decoration: underline;
  }
  @media (max-width: 48em) {
    flex-direction: column;
    width: 100%;
    font-size: ${(props) => props.theme.fontmd};

    span {
      margin-bottom: 1rem;
    }
  }
`;

const Footer = () => {
  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Section>
      <Suspense fallback={<Loading />}>{/* <Banner />{" "} */}</Suspense>
      <footer className="app_footer">
        <div className="footer_detail">
          <Container>
            <div className="footer_detail_list">
              {FooterNavigation.map((item, index) => (
                <div className="widget" key={index}>
                  <div className="widget_title">{item?.title}</div>
                  <ul>
                    {item?.items?.map((childItem, childIndex) => (
                      <li key={childIndex}>
                        <a target={childItem?.target} href={childItem?.path}>
                          {childItem?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </Container>
        </div>
        <div className="container">
          <div className="footer_wrapper">
            <div className="footer_left">
              <div className="policies">
                <a
                  href="https://docs.wallet-balancenetwork.io/guide"
                  target="blank"
                  rel="noreferer"
                  style={{ color: "#fff" }}
                >
                  Disclaimer
                </a>
              </div>
            </div>

            <div className="copyright">
              <p>
                2023 © All right reserved by{" "}
                <a
                  style={{ color: "#fbbe15" }}
                  href="https://balancenetwork.io"
                >
                  Balance Network
                </a>
                .
              </p>
            </div>

            <ul className="social_media_list">
              {SOCIAL_MEDIA.map((social, index) => (
                <li key={index}>
                  <a
                    href={social.href}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Icon icon={social.icon} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </footer>
    </Section>
  );
};

export default Footer;
