import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import walletLogo from "../assets/balance-wallet-cift.svg"
const LogoText = styled.h1`

font-size: 1.2em;
color: #fbbe16;
transition: all 0.2s ease;

&:hover{
    transform: scale(1.1);
}

@media (max-width: 64em){
font-size: ${props => props.theme.fontlg};

}
`

const Logo = () => {
  return (
    <LogoText>
        <Link to="/">
          <img src={walletLogo} className="logom" width="156" heigth="42"/>
        </Link>
    </LogoText>
  )
}

export default Logo
