import PropTypes from "prop-types";

// icon library
import * as Icons from "./lib";

// icon match by query
const match = {
  target: <Icons.Target />,
  search: <Icons.Search />,
  hourglass: <Icons.Hourglass />,
  stake: <Icons.Stake />,
  globe: <Icons.Globe />,
  twitter: <Icons.Twitter />,
  telegram: <Icons.Telegram />,
  sun: <Icons.Sun />,
  moon: <Icons.Moon />,
  reddit: <Icons.Reddit />,
  discord: <Icons.Discord />,
  medium: <Icons.Medium />,
  instagram: <Icons.Instagram />,
  touch: <Icons.Touch />,
  play: <Icons.Play />,
  tick_alt: <Icons.TickAlt />,
  tick: <Icons.Tick />,
  info: <Icons.Info />,
  menu: <Icons.Menu />,
  close: <Icons.Close />
};

/**
  @param {icon} query for matching icon
  @returns {node | null} svg element || null (not found)
*/
const Icon = ({ icon }) => {
  if (!match[icon]) return null;

  return <span  style={{color:"white",width:"24",height:"24"}}>{match[icon]}</span>;
};

export default Icon;

Icon.propType = {
  icon: PropTypes.string.isRequired
};
