const FooterNavigation = [
	{
		title: "About",
		items: [
			{
				name: "About Us",
				path: "https://docs.balancenetwork.io/views/About/aboutus.html",
				target: "_blank",
			},
			{
				name: "Contact Us",
				path: "mailto:support@balancenetwork.io",
				target: "_blank",
			},
			{
				name: "Terms of Use",
				path: "https://docs.balancenetwork.io/views/About/termsofuse.html",
				target: "_blank",
			},
			{
				name: "Risk & Compliance Disclaimer",
				path: "https://docs.balancenetwork.io/views/About/riskcompliance.html",
				target: "_blank",
			},
			{
				name: "Cookie Policy",
				path: "https://docs.balancenetwork.io/views/About/privacypolicy.html",
				target: "_blank",
			},
			{
				name: "Privacy Policy",
				path: "https://docs.balancenetwork.io/views/About/privacypolicy.html",
				target: "_blank",
			},
		],
	},
	{
		title: "Products",
		items: [
			{
				name: "Exchange",
				path: "https://exchange-balancenetwork.io/",
				target: "_blank",
			},
			{
				name: "DeFi",
				path: "https://www.swap-balancenetwork.io/",
				target: "_blank",
			},
			{
				name: "NFT",
				path: "https://nft.balancenetwork.io/",
				target: "_blank",
			},
			,
			{
				name: "Web 3 Wallet",
				path: "https://www.wallet-balancenetwork.io/",
				target: "_blank",
			},
			// {
			//   name: "BLN Token",
			//   path: "https://www.balancenetwork.io/bln-token",
			//   target: "_blank",
			// },
		],
	},
	{
		title: "Services",
		items: [
			{
				name: "Launchpad Application",
				path: "https://docs.google.com/forms/d/e/1FAIpQLSdlRavHyDigoaUbz6iC16ZIWB2uY4PiQ4rjRtqMLwCxNutkUQ/viewform",
				target: "_blank",
			},
			// {
			// 	name: "VIP services",
			// 	path: "https://ost.swap-balancenetwork.io/",
			// 	target: "_blank",
			// },
			{
				name: "Buy Crypto",
				path: "https://www.balancenetwork.io/buy",
				target: "_blank",
			},
		],
	},
	{
		title: "Support",
		items: [
			{
				name: "Support Center",
				path: "https://docs.balancenetwork.io/views/Support/support.html",
				target: "_blank",
			},
			{
				name: "Community",
				path: "",
				target: "_blank",
			},
			{
				name: "Verification",
				path: "https://www.balancenetwork.io/verification",
				target: "_blank",
			},
			{
				name: "Docs",
				path: "https://docs.balancenetwork.io/",
				target: "_blank",
			},
		],
	},
];

export { FooterNavigation };
