import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import Accordion from "../Accordion";

const Section = styled.section`
  min-height: 100vh;
  height: auto;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  position: relative;
  color: ${(props) => props.theme.body};
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};

  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;

  display: flex;
  justify-content: space-between;
  align-content: center;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
    flex-direction: column;

    & > *:last-child {
      & > *:first-child {
        margin-top: 0;
      }
    }
  }
`;
const Box = styled.div`
  width: 45%;
  @media (max-width: 64em) {
    align-self: center;
  }
  @media (max-width: 48em) {
    width: 90%;
  }
`;

const Faq = () => {
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useLayoutEffect(() => {
    let element = ref.current;

    ScrollTrigger.create({
      trigger: element,
      start: "bottom bottom",
      end: "bottom top",
      pin: true,
      pinSpacing: false,
      scrub: 1,
      // markers:true,
    });

    return () => {
      ScrollTrigger.kill();
    };
  }, []);

  return (
    <Section ref={ref} id="faq">
      <Title>Faq</Title>

      <Container>
        <Box>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="
What is Balance Wallet"
          >
            A Crypto Wallet & Gateway To Blockchain Apps
          </Accordion>

          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="Explore
blockchain apps"
          >
            Balance Wallet provides the simplest yet most secure way to connect
            to blockchain-based applications. You are always in control when
            interacting on the new decentralized web.
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="
            Is there an IOS Mobile APP?"
          >
            YES! Visit App Store Here:{" "}
            <a
              href="https://apps.apple.com/tr/app/balance-wallet-app/id1658310376"
              style={{ color: "blue" }}
            >
              Balance Wallet
            </a>
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="
            What is decentralized cryptocurrency wallet?"
          >
            <p>
              A decentralized cryptocurrency wallet is a type of cryptocurrency
              wallet that is not controlled by a single entity, but rather
              operates in a decentralized manner using blockchain technology.
              This means that the wallet is not held or maintained by a central
              authority, such as a bank or government, but is instead managed by
              a network of users on a distributed ledger.
            </p>
            <br></br>
            <p>
              Decentralized cryptocurrency wallets offer a number of benefits
              over traditional, centralized wallets. They are generally
              considered to be more secure, as they are not controlled by a
              single entity that could potentially be hacked or go offline. They
              also offer greater privacy, as users are able to hold and manage
              their own private keys, rather than having to rely on a third
              party to hold them.
            </p>
            <br></br>
            <p>
              Some popular decentralized cryptocurrency wallets include Balance
              Wallet, MetaMask, MyEtherWallet, and Trust Wallet. These wallets
              allow users to store, manage, and transact with a variety of
              different cryptocurrencies, such as Bitcoin, Ethereum, and
              Litecoin.
            </p>
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="
            How does it work?"
          >
            <p>
              Decentralized cryptocurrency wallets work by allowing users to
              store and manage their own private keys, which are used to access
              and transact with their cryptocurrency holdings. These private
              keys are generated and stored locally on the user's device, rather
              than being held by a central authority.
            </p>
            <br></br>
            <p>
              When a user wants to send or receive cryptocurrency, they use
              their decentralized wallet to initiate the transaction. The
              transaction is then broadcast to the cryptocurrency network, where
              it is validated by network nodes and added to the blockchain. Once
              the transaction is confirmed and included in the blockchain, it is
              considered to be irrevocable and the funds have been transferred.
            </p>
            <br></br>
            <p>
              In addition to storing and managing private keys, decentralized
              cryptocurrency wallets may also offer additional features, such as
              the ability to view and manage cryptocurrency balances, create and
              sign transactions, and interact with decentralized applications
              (DApps).
            </p>
            <br></br>
            <p>
              Decentralized cryptocurrency wallets are often compatible with a
              variety of different cryptocurrencies and blockchain platforms,
              and may be accessed through a variety of different devices,
              including desktop computers, mobile phones, and tablets.
            </p>
          </Accordion>
        </Box>
        <Box>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="Buy, store,
send and swap
tokens"
          >
            Available as a mobile app, Balance Wallet
            equips you with a key vault, secure login, token wallet, and token
            exchange—everything you need to manage your digital assets
          </Accordion>
          <Accordion ScrollTrigger={ScrollTrigger} title="Own your data">
            Balance Wallet generates passwords and keys on your device, so only
            you have access to your accounts and data. You always choose what to
            share and what to keep private
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="
            Is there an Android Mobile App?"
          >
            YES! Visit Google Play Here:{" "}
            <a
              href="https://play.google.com/store/apps/details?id=io.balancenetwork.wallet"
              style={{ color: "blue" }}
            >
              Balance Wallet
            </a>
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="
            How to import tokens?"
          >
            <p> In your mobile wallet, go to “Portfolio” and “add token” </p>
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="
            Are decentralized cryptocurrency wallets safe?"
          >
            <p>
              Decentralized cryptocurrency wallets are generally considered to
              be more secure than centralized wallets, as they are not
              controlled by a single entity that could potentially be hacked or
              go offline. In a decentralized wallet system, users are able to
              store and manage their own private keys, which are used to access
              and control their cryptocurrency holdings. These private keys are
              created and stored locally on the user's device, and are not held
              by a central authority.
            </p>
            <br></br>
            <p>
              That being said, decentralized cryptocurrency wallets are not
              completely immune to security hreats. It is important for users to
              take steps to secure their wallets and protect their private keys,
              such as by using strong, unique passwords, enabling two-factor
              authentication, and keeping their wallet software up to date
            </p>
            <br></br>
            <p>
              It is also important for users to be aware of potential scams and
              phishing attacks, which may attempt to trick users into disclosing
              their private keys or sending funds to fraudulent addresses. It is
              generally a good idea for users to do their own research and only
              interact with reputable sources when using decentralized
              cryptocurrency wallets
            </p>
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title="
            What is the difference between decentralized and centralized cryptocurrency wallets?"
          >
            <p>
              The main difference between decentralized and centralized
              cryptocurrency wallets is who controls the private keys. In a
              centralized wallet system, the private keys are held by a central
              authority, such as a bank or exchange. This means that the central
              authority has control over the user's funds, and the user must
              trust that the central authority will properly secure and manage
              their keys.
            </p>
            <br></br>
            <p>
              In a decentralized wallet system, users are able to generate and
              store their own private keys, which are used to access and control
              their cryptocurrency holdings. These private keys are created and
              stored locally on the user's device, and are not held by a central
              authority. This means that users have full control over their
              funds and do not need to rely on a third party to manage their
              keys.
            </p>
            <br></br>
            <p>
              There are a number of advantages to using a decentralized
              cryptocurrency wallet. They are generally considered to be more
              secure, as they are not controlled by a single entity that could
              potentially be hacked or go offline. They also offer greater
              privacy, as users are able to hold and manage their own private
              keys, rather than having to rely on a third party to hold them.
              However, it is important for users to take steps to secure their
              wallets and protect their private keys, as decentralized wallets
              are not completely immune to security threats.
            </p>
          </Accordion>
        </Box>
      </Container>
    </Section>
  );
};

export default Faq;
