import { menuItems } from '../../menuItems2';
import MenuItems from './MenuItems';
const NavbarDrop2 = ({scrollTo}) => {
  const sc = scrollTo
  return (
    <nav>
      <ul className="menus">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return (
            <MenuItems
              items={menu}
              key={index}
              sc={sc}
              depthLevel={depthLevel}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default NavbarDrop2;
