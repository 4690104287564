import Pdf from "./components/pdf/privacy-policy.pdf";
import Button from "./components/Button";
import googleLogo from "./assets/and.png";
export const menuItems = [
  {
    title: "Features",
    url: "",
    submenu: [
      {
        title: (
          <Button
            text="Google Play"
            resim={googleLogo}
            link="https://play.google.com/store/apps/details?id=io.balancenetwork.wallet"
          />
        ),
        url: "https://www.swap-balancenetwork.io/swap",
      },
      {
        title: (
          <Button
            text="Google Play"
            resim={googleLogo}
            link="https://play.google.com/store/apps/details?id=io.balancenetwork.wallet"
          />
        ),
        url: "https://www.swap-balancenetwork.io/",
      },
    ],
  },
];
